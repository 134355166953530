import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import homeIcon from "../../assets/images/home_icon.png";
import dbIcon from "../../assets/images/dashbordIcon.svg";
import assetsIcon from "../../assets/images/assets_icon.png";
import aDIcon from "../../assets/images/AD_icon.svg";
import sBIcon from "../../assets/images/SB_icon.svg";
import inventoryIcon from "../../assets/images/inventory_icon.svg";
import config from "../../config"
import { getLocalStorageInfo } from "../../utils";
import { Tooltip } from "@material-ui/core";
export const MainNavLink = ({ match }) => {
	const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)

	return (
		<Fragment>
			<ul className="list-unstyled">
				<li>
					<a href={config.domain.subDomian} target="_blank" >
						<img src={homeIcon} alt="Home" width="25" />
						<p>Home</p>
					</a>
				</li>
				{smbcInstance ? null :<li>
					<Link className={match.path.includes("dashboard") ? "active" : ""} to="/dashboard" >
						<img src={dbIcon} alt="Dashboard" width="20" />
						<p>Dashboard</p>
					</Link>
				</li>}
				{smbcInstance ? <li>
					<Tooltip title={<span style={{color:"white"}}>Coming Soon</span>} placement="top">
					<Link className={match.path.includes("dashboard-section") ? "active" : ""}  >
						<img src={dbIcon} alt="dashboard-section" width="20" style={{opacity:'0.8'}} />
						<p > <span style={{opacity:'0.8'}}>Dashboard</span> <sup style={{color:"red"}}>Coming Soon</sup></p>
					</Link></Tooltip>
				</li> : null}
				<li>
					<Link  className={match.path === '/' ||  match.path.includes("list") ? "active" : ""} to="/assets-listing" >
						<img
							src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/asset_list_icon.png"
							alt="Assets"
							width="20"
						/>
						<p>Assets</p>
					</Link>
				</li>
				<li>
					<Link className={match.path.includes("fleet/ad") ? "active" : ""} to="/fleet/ad" >
						<img src={aDIcon} alt="fleet/ad" width="20" />
						<p>Airworthiness Directives</p>
					</Link>
				</li>
				
				<li>
					<Link className={match.path.includes("fleet/sb") ? "active" : ""} to="/fleet/sb" >
						<img src={sBIcon} alt="fleet/sb" width="20" />
						<p>Service Bulletins</p>
					</Link>
				</li>
				{smbcInstance ? <li>
					<Link className={match.path.includes("reports") ? "active" : ""} to="/reports/portfolio" >
						<img
							src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/report_icon.png"
							alt="reports"
							width="20"
						/>
						<p>Reports</p>
					</Link>
				</li>: null}
				{smbcInstance ? <li>
					<Link className={match.path.includes("configure") ? "active" : ""} to="/configure" >
						<img
							src="https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/settings.png"
							alt="config"
							width="20"
						/>
						<p>Configure</p>
					</Link>
				</li>: null}
			</ul>
		</Fragment>
	);
};

export default withRouter(MainNavLink);
