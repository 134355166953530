import React, { Fragment, useEffect, useState } from 'react'
import { Grid, TableCell, TableHead, TableBody, TableRow, Table, TableSortLabel, TablePagination, Paper } from '@material-ui/core';
import DownloadXLSAssetDetail from '../../../shared_elements/DownloadXLSAssetDetail';
import { globalExportService, globalGetService } from '../../../utils/globalApiServices';
import { downloadFileType, checkApiStatus, Comma_format } from '../../../utils';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { PageLoader, EmptyCollection } from "../../../shared_elements"
import { Link } from "react-router-dom"
import STableLoader from '../../../shared_elements/components/skeleton_loader/STableLoader';
import { backendDateFormat, dayMonthDateFormat } from '../../../constants';
import FilterComponent from '../../../shared_elements/components/filter_component';
import { dashboardFilterOptions } from '../index'
import ReportsTab from '../../fleet/components/ReportsTab';

function SmbcDashboardList() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [dashboardList, setDashboardList] = useState()
    const [pageLoader, setPageLoader] = useState(false)
    const [skeltonLoader, setSkeltonLoader] = useState(false)
    const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
    const [filterEngineTypes, setFilterEngineTypes] = useState([]);
    const [filterLessee, setFilterLessee] = useState([]);
    const [filter, setFilter] = useState({})
    const [date, setDate] = useState({ last_twelve_month_date: null, from_date: null, to_date: null })

    useEffect(() => {
        smbcDashboard({ per_page: 50 })
        getLessee()
        getEngineTypes()
    }, [])

    const createSortHandler = (key) => {
        let sortQuery = { sort: key };
        if (sortInfo.sort === key) {
            sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
        } else {
            sortQuery = { ...sortQuery, sort_by: 'asc' }
        }
        setSortInfo(sortQuery)
        smbcDashboard({ ...sortQuery, page: 1, per_page: 50 }, 'pageLoader');
    }

    const smbcDashboardExport = () => {
        setPageLoader(true)
        let queryParams = Object.assign({}, filter);
        globalExportService(`/camo/smbc_summary/?download=xls`, { ...queryParams })
            .then((response) => {
                setPageLoader(false)
                if (response.status === 500) {
                    enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                } else {
                    downloadFileType(response.data, `SMBC_Summary`, 'xls');
                    enqueueSnackbar("SMBC Summary Download Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
                }
            });
    }

    const smbcDashboard = (query, loader) => {
        loader === "pageLoader" ? setPageLoader(true) : setSkeltonLoader(true)
        globalGetService(`/camo/smbc_summary/`, { ...query })
            .then((response) => {
                loader === "pageLoader" ? setPageLoader(false) : setSkeltonLoader(false)
                setDashboardList(response.data?.data)
                setFilter(query, delete query.per_page, delete query?.last_twelve_month_date, delete query?.to_date)
            });
    }

    const onFieldChange = (keyParam, value) => {
        let newDateState = {};
        let queryParams = { per_page: 50 };

        if (keyParam === "last_twelve_month_date") {
            newDateState = { [keyParam]: value };
        } else {
            newDateState = {
                last_twelve_month_date: date?.last_twelve_month_date,
                [keyParam]: value
            };
        }
        if (date?.last_twelve_month_date) {
            queryParams.last_twelve_month_date = date?.last_twelve_month_date;
        }
        if (date?.from_date) {
            queryParams.from_date = date?.from_date;
        }
        queryParams[keyParam] = value;
        setDate(prevDate => ({ ...prevDate, ...newDateState }));
        smbcDashboard(queryParams, 'pageLoader');
    };

    const getEngineTypes = () => {
        globalGetService(`console/engine-types/`, { distinct: 1 })
            .then(response => {
                if (checkApiStatus(response)) {
                    setFilterEngineTypes(response.data.data.engineTypes)
                }
            })
    }
    const getLessee = () => {
        globalGetService(`console/lessees/?distinct=1`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setFilterLessee(response.data.data.lessees)
                }
            })
    }
    const filterObj = Object.assign({}, filter, delete filter?.page, delete filter?.sort, delete filter?.sort_by,)
    let filterOptions = Object.assign({}, dashboardFilterOptions);
    filterOptions = {
        ...filterOptions,
        engine_type: {
            ...filterOptions.engine_type,
            options: filterEngineTypes
        },
        lessee: {
            ...filterOptions.lessee,
            options: filterLessee
        },
    }
    const tableSorting = (key, name) => {
        return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
    }
    return (
        <Fragment>
             <ReportsTab
                tabIndex="portfolio"
            />
            <Paper square style={{ padding: '0px 5px', marginBottom: "14px" }}>
                <Grid container spacing={2}>
                    <Grid md={9}>
                        <div style={{ padding: "5px 10px" }}>
                            <FilterComponent
                                filter={filterObj}
                                filterMenu={filterOptions}
                                getResponseBack={(applyFilter) => smbcDashboard({ ...applyFilter, page: 1, per_page: dashboardList?.pagination?.per_page }, 'pageLoader')}
                            />
                        </div>
                    </Grid>
                    <Grid md={3}>
                        <ul className='list-inline' style={{ float: 'right' }}>
                            <li className='list-inline-item' style={{ marginRight: "15px" }}> <DownloadXLSAssetDetail files={{ title: "Export", extension: "xls", key: "" }} exportReportFn={() => smbcDashboardExport()} color='#bd10e0' /> </li>
                        </ul>
                    </Grid>
                </Grid>
            </Paper>

            {skeltonLoader ? <STableLoader count={7} /> : <Paper square style={{ padding: '5px 10px', marginBottom: "5px" }}>
                <h4 style={{ margin: "5px 0px", color: "#bd10e0" }}>Portfolio</h4>
                <div className='smbc-dasboard-list' style={{ height: (window.innerHeight - 205) + 'px' }} >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow >
                                <TableCell colSpan={16} style={{ textAlign: 'left' }}>Total Engine :- {dashboardList?.pagination.total} </TableCell>
                                <TableCell colSpan={4}>Installed</TableCell>
                                <TableCell colSpan={1}>Update</TableCell>
                                <TableCell colSpan={8}>Current Condition</TableCell>
                                <TableCell colSpan={4}>Average Utilization FC Per Months</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell> {tableSorting('owner', 'Owner')} </TableCell>
                                <TableCell> {tableSorting('lessee', 'Lessee')}</TableCell>
                                <TableCell> {tableSorting('operator', 'Operator')}</TableCell>
                                <TableCell style={{minWidth:"200px"}}>{tableSorting('on_lease', 'On Lease Y/N')} </TableCell>
                                <TableCell> {tableSorting('country', 'Country')}</TableCell>
                                <TableCell style={{minWidth:"200px"}}>  {tableSorting('eng_type', 'Engine Type')}</TableCell>
                                <TableCell>{tableSorting('esn', 'ESN')}</TableCell>
                                <TableCell>{tableSorting('qec', 'QEC')}</TableCell>
                                <TableCell>{tableSorting('manufacturing_date', 'DOM')}</TableCell>
                                <TableCell style={{minWidth:"200px"}}>{tableSorting('qec_part_number', 'QEC. Part No')}</TableCell>
                                <TableCell style={{minWidth:"200px"}}>{tableSorting('bag', 'Stand & Bag')}</TableCell>
                                <TableCell style={{minWidth:"200px"}}>{tableSorting('software_part_number', 'Software P/N')}</TableCell>
                                <TableCell style={{minWidth:"200px"}}>{tableSorting('software_version', 'Software Version')}</TableCell>
                                <TableCell style={{minWidth:"200px"}}>{tableSorting('lease_start_date', 'Lease Start Date')}</TableCell>
                                <TableCell style={{minWidth:"200px"}}>{tableSorting('lease_term_date', 'Lease Term Date')}</TableCell>
                                <TableCell style={{minWidth:"200px"}}>{tableSorting('month_remaining', 'Months Remain')}</TableCell>
                                <TableCell>{tableSorting('tail', 'Tail')}</TableCell>
                                <TableCell>{tableSorting('psn', 'PSN')}</TableCell>
                                <TableCell>{tableSorting('thrust', 'Thrust')}</TableCell>
                                <TableCell>{tableSorting('date', 'Date')}</TableCell>
                                <TableCell>{tableSorting('update_date', 'Update')}</TableCell>
                                <TableCell >{tableSorting('current_codition_tsn', 'TSN')}</TableCell>
                                <TableCell>{tableSorting('current_codition_csn', 'CSN')}</TableCell>
                                <TableCell>{tableSorting('current_codition_tsr', 'TSR')}</TableCell>
                                <TableCell>{tableSorting('current_codition_csr', 'CSR')}</TableCell>
                                <TableCell style={{minWidth:"200px"}}>{tableSorting('current_codition_limiter', 'Limiter')}</TableCell>
                                <TableCell style={{minWidth:"200px"}}>{tableSorting('min_llp_date', 'Min LLP prior to Term Date')}</TableCell>
                                <TableCell style={{minWidth:"200px"}}>{tableSorting('hpt_blade_limit', 'HPT Blade Limiter')}</TableCell>
                                <TableCell style={{minWidth:"200px"}}>{tableSorting('current_limiter', 'Current Limiter')}</TableCell>
                                <TableCell className='last-tweleve-month' >
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            margin="normal"
                                            label="Past 12 Months"
                                            format={dayMonthDateFormat}x
                                            disableFuture
                                            style={{ width: "180px" }}
                                            value={date?.last_twelve_month_date || null}
                                            InputLabelProps={{ shrink: true }}
                                            error={false}
                                            helperText={''}
                                            clearable={true}
                                            onChange={(data, value) => onFieldChange('last_twelve_month_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                            inputVariant='outlined'
                                        />
                                    </MuiPickersUtilsProvider>
                                </TableCell>
                                <TableCell className='average-utils-date' >
                                    <ul className='list-inline'>
                                        <li className='list-inline-item'>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    label="From Date"
                                                    format={dayMonthDateFormat}
                                                    disableFuture
                                                    disabled={!date?.last_twelve_month_date}
                                                    minDate={moment(date?.last_twelve_month_date).add(1, 'days')}
                                                    style={{ width: "180px" }}
                                                    value={date?.from_date || null}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={false}
                                                    helperText={''}
                                                    onChange={(data, value) => onFieldChange('from_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                                    inputVariant='outlined'
                                                />
                                            </MuiPickersUtilsProvider>
                                        </li>
                                        <li className='list-inline-item' style={{ marginLeft: "10px" }}>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <KeyboardDatePicker
                                                    margin="normal"
                                                    label="To Date"
                                                    format={dayMonthDateFormat}
                                                    disableFuture
                                                    disabled={!date?.last_twelve_month_date}
                                                    minDate={moment(date?.from_date)}
                                                    style={{ width: "180px" }}
                                                    value={date?.to_date || null}
                                                    InputLabelProps={{ shrink: true }}
                                                    error={false}
                                                    helperText={''}
                                                    onChange={(data, value) => onFieldChange('to_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                                    inputVariant='outlined'
                                                />
                                            </MuiPickersUtilsProvider>
                                        </li>
                                    </ul>
                                </TableCell>
                                <TableCell>{tableSorting('delta_ratio', 'Delta Ratio')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dashboardList?.asset.map((item, index) =>
                                <TableRow component={Link} to={`/${item.asset_id}/2/op_history/list`}>
                                    <TableCell>{item.owner || '--'} </TableCell>
                                    <TableCell>{item.lessee || '--'}</TableCell>
                                    <TableCell>{item.operator || '--'}</TableCell>
                                    <TableCell>{item.on_lease || '--'}</TableCell>
                                    <TableCell>{item.country || '--'}</TableCell>
                                    <TableCell>{item.eng_type || '--'}</TableCell>
                                    <TableCell>{item.esn || '--'}</TableCell>
                                    <TableCell>{item.qec || '--'}</TableCell>
                                    <TableCell>{item.manufacturing_date ? moment(item.manufacturing_date).format(dayMonthDateFormat) : "--"}</TableCell>
                                    <TableCell>{item.qec_part_number || '--'}</TableCell>
                                    <TableCell>{item.stand}-{item.bag}</TableCell>
                                    <TableCell>{item.software_part_number || '--'}</TableCell>
                                    <TableCell>{item.software_version || '--'}</TableCell>
                                    <TableCell>{item.lease_start_date ? moment(item.lease_start_date).format(dayMonthDateFormat) : "--"}</TableCell>
                                    <TableCell>{item.lease_term_date ? moment(item.lease_term_date).format(dayMonthDateFormat) : "--"}</TableCell>
                                    <TableCell>{item.month_remaining || '--'}</TableCell>
                                    <TableCell>{item.tail || '--'}</TableCell>
                                    <TableCell>{item.psn || '--'}</TableCell>
                                    <TableCell>{item.thrust || '--'}</TableCell>
                                    <TableCell>{item.date || '--'}</TableCell>
                                    <TableCell>{item.update_date ? moment(item.update_date).format(dayMonthDateFormat) : "--"}</TableCell>
                                    <TableCell>{item.current_codition_tsn ? Comma_format(item.current_codition_tsn.toFixed(2)) : '--'}</TableCell>
                                    <TableCell>{item.current_codition_csn ? Comma_format(parseInt(item.current_codition_csn)) : '--'}</TableCell>
                                    <TableCell>{item.current_codition_tsr ? Comma_format(item.current_codition_tsr.toFixed(2)) : '--'}</TableCell>
                                    <TableCell>{item.current_codition_csr ? Comma_format(parseInt(item.current_codition_csr)) : '--'}</TableCell>
                                    <TableCell>{item.current_codition_limiter ? Comma_format(parseInt(item.current_codition_limiter)) : item.current_codition_limiter === null ? 'N/A' : 0}</TableCell>
                                    <TableCell>{moment(item.min_llp_date).isValid() ? moment(item.min_llp_date).format(dayMonthDateFormat) : item.min_llp_date || '--'}</TableCell>
                                    <TableCell>{item.hpt_blade_limit > 0 ? item.hpt_blade_limit : item.hpt_blade_limit === null ? 'N/A' : 0}</TableCell>
                                    <TableCell>{item.current_limiter ? Comma_format(parseInt(item.current_limiter)) : item.current_limiter === null ? 'N/A' : 0}</TableCell>
                                    <TableCell>{item?.util_last_twelve_month || '--'}</TableCell>
                                    <TableCell>{item?.util_from_date || "--"}</TableCell>
                                    <TableCell>{item.delta_ratio || '--'}</TableCell>
                                </TableRow>
                            )
                            }
                        </TableBody>
                    </Table>
                    {!dashboardList?.asset ? <EmptyCollection description={'No records found'} /> : null}
                </div>
                <TablePagination
                    rowsPerPageOptions={[20, 50, 100, 200]}
                    component="div"
                    count={dashboardList?.pagination?.total}
                    rowsPerPage={parseInt(dashboardList?.pagination?.per_page)}
                    page={dashboardList?.pagination?.page - 1}
                    backIconButtonProps={{ "aria-label": "previous page" }}
                    nextIconButtonProps={{ "aria-label": "next page" }}
                    onChangePage={(event, newPage) => smbcDashboard({ per_page: dashboardList.pagination.per_page, page: newPage + 1, last_twelve_month_date: date ? date?.last_twelve_month_date : null }, 'pageLoader')}
                    onChangeRowsPerPage={(event) => smbcDashboard({ per_page: event.target.value, last_twelve_month_date: date ? date?.last_twelve_month_date : null }, "pageLoader")}
                />
            </Paper>
            }
            {pageLoader ? <PageLoader /> : null}
        </Fragment>

    )
}

export default SmbcDashboardList